import React, { useEffect, useState } from 'react';
import AdminTable from '../../components/tables/Table';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsersThunk, updateUserStatusThunk } from '../../redux/users/thunks';
import { Alert, Container } from "react-bootstrap";
import { api_do_url } from '../../config';

export default function ListarUsuarios() {
  const dispatch = useDispatch();
  const bodyData = useSelector((state) => state.users.users);
  const loading = useSelector((state) => state.users.usersLoading);
  const error = useSelector((state) => state.users.usersError);
  const userMessage = useSelector(state => state.users.userMessage);
  const [alert, setAlert] = useState(true);
  const [refresh, setRefresh] = useState(false);

  const tableHeader = [
    { label: "Nombre", name: "name" },
    { label: "Apellido", name: "lastName" },
    { label: "Email", name: "email" },
    { label: "Tel", name: "phone" },
    { label: "Categoria", name: "category", defaultValue: "Adherente" },
    { label: "Status", name: "membershipStatus", replacement: [{ authorized: "Activo" }, { nonMember: "No socio" }, { pending: "Pendiente" }, { review: 'Revisión' }] },
    { label: "Fecha", name: "createdAt", formatterDate: true },
    { label: "Ultimo pago", name: "subscription.lastCharged", formatterDate: true },
    { label: "Tipo de pago", name: "subscription.type", replacement: [{ wire: "Transferencia" }, { credit: "Mercado Pago" }] },
    { label: "Monto", name: "subscription.chargedAmount" },
  ];

  const block = (id) => {
    dispatch(updateUserStatusThunk({ id, status: 'cancelled', action: 'Bloquear' }));
    setRefresh(!refresh);
  };

  const unblock = (id) => {
    dispatch(updateUserStatusThunk({ id, status: 'nonMember', action: 'Bloquear' }));
    setRefresh(!refresh);
  };

  const download = async (id, user) => {
    window.open(`${api_do_url}/${user.cv}`, '_blank');
  };

  const buttons = [
    {
      name: "Bloquear",
      overlay: "Bloquear Usuario",
      icon: "ban",
      conditional: { key: "membershipStatus", opossiteValues: ["cancelled"] },
      className: 'btnDanger',
      action: block,
      confirmMessage: "¿Está seguro de querer bloquear este usuario?",
    },
    {
      name: "desbloquear",
      overlay: "desbloquear Usuario",
      icon: "unlock",
      conditional: { key: "membershipStatus", values: ["cancelled"] },
      className: 'btnGreen',
      action: unblock,
      confirmMessage: "¿Está seguro de querer desbloquear este usuario?",
    },
    {
      name: "Edit",
      overlay: "Editar usuario",
      icon: "edit",
      path: "users/edit",
    },
    {
      name: "details",
      overlay: "Ver todos los datos",
      icon: "eye",
      path: "users/details",
    },
    {
      name: "Descargar CV",
      overlay: "Descargar CV",
      icon: "download",
      conditional: { key: "cv", null: true },
      action: download
    }
  ];

  useEffect(() => {
    dispatch(getAllUsersThunk());
  }, [refresh]);

  const headersXlsx = {
    name: "Nombre",
    lastName: "Apellido",
    email: "Email",
    membershipStatus: "Estado Membresia",
    createdAt: "Fecha Creacion",
    category: "Categoria",
    phone: "Telefono",
    "subscription.lastCharged": "Ultimo pago"
  };

  const headersXlsxAll = {
    name: "Nombre",
    lastName: "Apellido",
    email: "Email",
    membershipStatus: "Estado Membresia",
    createdAt: "Fecha Creacion",
    category: "Categoria",
    phone: "Telefono",
    dni: "DNI",
    "subscription.lastCharged": "Fecha ultimo pago",
    "subscription.chargedAmount": "Monto ultimo pago",
    country: "Pais",
    province: "Provincia",
    postalCode: "Codigo Postal",
    address: "Direccion",
    profession: "Profesion",
    specialty: "Especialidad",
    title: "Titulo",
    jobAddress: "Direccion de trabajo",
    workPlace: "Lugar de trabajo",
    zone: "Zona de trabajo",
    genre: "genero",
    dateOfBirth: "Fecha de nacimiento"
  };

  return (
    <>
      {error ? (
        <p>Ha ocurrido un error. Por favor vuelva en intentar en unos minutos</p>
      ) : loading ? (
        <p>Loading</p>
      ) : (
        <Container>
          {(userMessage && alert) &&
            <Alert className='text-center' variant={userMessage ? 'success' : 'danger'} onClose={() => setAlert(false)} dismissible>
              {userMessage}
            </Alert>
          }
          <AdminTable
            bodyData={bodyData}
            tableHeader={tableHeader}
            buttons={buttons}
            buttonsWidth={'200px'}
            multiSelect={true}
            headersXlsx={headersXlsx}
            headersXlsxAll={headersXlsxAll}
            filterLastMonth={true}
          />
        </Container>
      )}
    </>
  );
}
