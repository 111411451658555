import { Pagination, Col, Row, Table, Form, FloatingLabel, Button, Tooltip, OverlayTrigger, Alert } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExportExcel from "../excel/Export";
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEnvelope, faCertificate, faGift, faEdit, faTrash, faBan, faCreditCard, faEye, faUnlock, faCheck, faUser, faMoneyBill, faNetworkWired, faUndo, faCopy } from "@fortawesome/free-solid-svg-icons";
import styles from './Table.module.css';
library.add(faDownload, faEnvelope, faCertificate, faGift, faEdit, faTrash, faBan, faCreditCard, faEye, faUnlock, faCheck, faUser, faMoneyBill, faNetworkWired, faUndo, faCopy)

export default function AdminTable({
  bodyData,
  tableHeader,
  buttons,
  buttonsWidth,
  message,
  errMessage,
  page,
  size,
  multiSelect,
  headersXlsx,
  headersXlsxAll,
  filterLastMonth
}) {
  const navigate = useNavigate();

  // Estados para los filtros
  const [searchTerm, setSearchTerm] = useState("");
  const [filterLastMonthActive, setFilterLastMonthActive] = useState(false);
  const [showCancelled, setShowCancelled] = useState(false);

  // Estado para la data filtrada (se utiliza para el Excel y la tabla)
  const [data, setData] = useState(bodyData);
  const [paginatedData, setPaginatedData] = useState([]);
  const [lastSorted, setLastSorted] = useState({});
  const [alert, setAlert] = useState(true);
  const [alertMessage, setAlertMessage] = useState(message ? message : errMessage);
  const [statePage, setStatePage] = useState(page ? page : 0);
  const [stateSize, setStateSize] = useState(size ? size : 10);
  const [totalPages, setTotalPages] = useState(Math.ceil(data.length / stateSize));
  const [ordered, setOrdered] = useState(true)
  const [checkedItems, setCheckedItems] = useState([]);

  // Recalcula la data filtrada a partir de bodyData y los filtros activos
  useEffect(() => {
    let tempData = [...bodyData];
    if (searchTerm.trim() !== "") {
      tempData = tempData.filter(item => findMatch(searchTerm, item));
    }
    if (filterLastMonthActive) {
      let dateNow = new Date();
      tempData = tempData.filter(item =>
        dateNow.getFullYear() === new Date(item.createdAt).getFullYear() &&
        dateNow.getMonth() === new Date(item.createdAt).getMonth()
      );
    }
    // Si no se activa el checkbox, se ocultan los usuarios cancelados
    if (!showCancelled) {
      tempData = tempData.filter(item => item.membershipStatus !== "cancelled");
    }
    setData(tempData);
  }, [bodyData, searchTerm, filterLastMonthActive, showCancelled]);

  useEffect(() => {
    setPaginatedData(
      [...data].slice(statePage * stateSize, (statePage + 1) * stateSize)
    );
    setTotalPages(Math.ceil(data.length / stateSize));
    setAlertMessage(message ? message : errMessage);
  }, [data, message, errMessage, statePage, stateSize, ordered]);

  const getNestedValue = ({
    obj,
    path,
    positive = null,
    negative = null,
    replacement = null,
    formatterDate = null,
    existValueCondition = null,
    defaultValue = null,
    count = 0,
  }) => {
    if (!obj) {
      return obj;
    }
    count++;
    const copyPath = path;
    let pathToArray = copyPath.split(".");
    let currentPath = pathToArray[count - 1];
    if (count < pathToArray.length) {
      return getNestedValue({
        obj: obj[currentPath],
        path,
        positive,
        negative,
        replacement,
        formatterDate,
        existValueCondition,
        defaultValue,
        count
      });
    } else {
      if (existValueCondition) {
        if (obj[currentPath] !== undefined) {
          return positive;
        } else if (obj[currentPath] === undefined) {
          return negative;
        }
      }
      if (obj[currentPath] === true) {
        return positive;
      } else if (obj[currentPath] === false) {
        return negative;
      }
      if (replacement) {
        let search = replacement.find(item => item[obj[currentPath]]);
        if (search) return search[obj[currentPath]];
      }
      if (formatterDate) {
        return obj[currentPath]?.substring(0, 10);
      }
      return obj[currentPath] !== undefined ? obj[currentPath] : defaultValue;
    }
  };

  const extractValues = (obj) => {
    let values = [];
    for (const key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        values = values.concat(extractValues(obj[key]));
      } else {
        values.push(obj[key]);
      }
    }
    return values;
  };

  const findMatch = (value, obj) => {
    const searchWords = value.toLowerCase().split(' ');
    let allValues = extractValues(obj);
    tableHeader.forEach(th => {
      const extractedValue = getNestedValue({
        obj: obj,
        path: th.name,
        positive: th.positive,
        negative: th.negative,
        replacement: th.replacement,
        formatterDate: th.formatterDate,
        existValueCondition: th.existValueCondition,
        defaultValue: th.defaultValue
      });
      if (extractedValue != null) {
        allValues.push(extractedValue);
      }
    });
    const strings = allValues.map((v) => v?.toString().toLowerCase());
    return searchWords.every((word) =>
      strings.some((item) => item?.includes(word))
    );
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    setStatePage(0);
  };

  const handleLastMonth = (e) => {
    setFilterLastMonthActive(e.target.checked);
    setStatePage(0);
  };

  const handleShowCancelled = (e) => {
    setShowCancelled(e.target.checked);
    setStatePage(0);
  };

  const sort = (columnName) => {
    const isLastSortedAsc = lastSorted.name === columnName && lastSorted.order === "asc";
    const asc = isLastSortedAsc ? "desc" : "asc";

    const columnConfig = tableHeader.find(th => th.name === columnName);

    const getValue = (obj) => getNestedValue({
      obj,
      path: columnConfig.name,
      positive: columnConfig.positive,
      negative: columnConfig.negative,
      replacement: columnConfig.replacement,
      formatterDate: columnConfig.formatterDate,
      existValueCondition: columnConfig.existValueCondition,
      defaultValue: columnConfig.defaultValue
    });

    const sorted = [...data].sort((a, b) => {
      const valA = getValue(a);
      const valB = getValue(b);

      if (valA == null && valB == null) return 0;
      if (valA == null) return asc === "asc" ? -1 : 1;
      if (valB == null) return asc === "asc" ? 1 : -1;

      return (typeof valA === 'number' && typeof valB === 'number')
        ? (asc === "asc" ? valA - valB : valB - valA)
        : (asc === "asc" ? String(valA).localeCompare(String(valB)) : String(valB).localeCompare(String(valA)));
    });

    setData(sorted);
    setStatePage(0);
    setOrdered(!ordered);
    setLastSorted({ name: columnName, order: asc });
  };

  const showPagination = () => {
    let active = statePage;
    let maxShownPages = totalPages > 8 ? 8 : totalPages;
    let items = [];
    if (statePage > 0) {
      items.push(
        <Pagination.Prev
          key={statePage - 1}
          onClick={() => {
            setStatePage(statePage - 1);
          }}
        />
      );
    }
    for (let number = statePage; items.length <= maxShownPages && number < totalPages; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === active}
          onClick={() => setStatePage(number)}
        >
          {number + 1}
        </Pagination.Item>
      );
    }
    if (statePage < totalPages - 1) {
      items.push(
        <Pagination.Next
          key={'next'}
          onClick={() => {
            setStatePage(statePage + 1);
          }}
        />
      );
    }
    return items;
  };

  const pageLengthChange = (e) => {
    setStateSize(e.target.value);
    setTotalPages(Math.ceil(data.length / e.target.value));
    setStatePage(0);
  };

  const checkboxChange = (e) => {
    let items = [...checkedItems];
    let item_id = e.target.value;
    let index = checkedItems.indexOf(item_id);
    if (index > -1) { 
      items.splice(index, 1);
      setCheckedItems(items);
    } else {
      setCheckedItems([...checkedItems, item_id]);
    }
  };

  const checkAll = (e) => {
    if (e.target.checked) {
      setCheckedItems(data.map(item => item._id));
    } else {
      setCheckedItems([]);
    }
  };

  return (
    <>
      <Row>
        {alertMessage && alert && (
          <Alert
            className="text-center"
            variant={message ? "success" : "danger"}
            onClose={() => setAlert(false)}
            dismissible
          >
            {alertMessage}
          </Alert>
        )}
        <Col xs={12} style={{ marginBottom: '20px' }}>
          <Col xs={3} style={{ display: "flex" }}>
            {headersXlsx && <ExportExcel csvData={data} fileName='excel' btnTitle={"Excel"} headersXlsx={headersXlsx} />}
            {headersXlsxAll && <ExportExcel csvData={data} fileName='excel' btnTitle={"Excel completo"} headersXlsx={headersXlsxAll} />}
          </Col>
        </Col>
        
        <Col xs={12} md={4}>
          <FloatingLabel controlId="floatingInput" label="Buscar en la tabla" className="mb-3">
            <Form.Control required type="text" name="filter" onChange={handleChange} />
          </FloatingLabel>
          {filterLastMonth && (
            <FloatingLabel controlId="floatingInput" className="mb-3">
              <Form.Check type='checkbox' label="Usuarios registrados último mes" onChange={handleLastMonth} />
            </FloatingLabel>
          )}
          <FloatingLabel controlId="floatingInput" className="mb-3">
            <Form.Check type='checkbox' label="Mostrar usuarios cancelados" onChange={handleShowCancelled} />
          </FloatingLabel>
        </Col>
        <Col xs={12} md={4} className="justify-content-end">
          <p>Registros totales: {data.length}</p>
        </Col>
        <Col xs={12} sm={{ span: 2, offset: 2 }}>
          <FloatingLabel label={"cantidad de filas"} className="mb-3">
            <Form.Select aria-label="Floating label select example" name={"filas"} onChange={pageLengthChange}>
              <option>{stateSize}</option>
              <option>5</option>
              <option>10</option>
              <option>20</option>
              <option>30</option>
              <option>40</option>
              <option>50</option>
            </Form.Select>
            <Form.Control.Feedback />
          </FloatingLabel>
        </Col>
        <Col style={{ marginBottom: '15px' }}>
          {checkedItems.length > 0 &&
            buttons.map(({ overlay, icon, className, bulkAction, path, confirmMessage, bulk }, index) => {
              if (bulk) {
                return (
                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>{overlay}</Tooltip>} key={index}>
                    <Button
                      className={styles[className]}
                      style={{ marginRight: '6px' }}
                      onClick={() => {
                        window.confirm(confirmMessage) && bulkAction(checkedItems);
                      }}
                    >
                      <FontAwesomeIcon icon={icon} className="fa-table" />
                    </Button>
                  </OverlayTrigger>
                );
              }
              return null;
            })
          }
        </Col>
        <Table striped bordered hover>
          <thead>
            <tr>
              {multiSelect && (
                <th>
                  <input type={"checkbox"} onChange={checkAll} value="checkAll" />
                </th>
              )}
              {tableHeader.map((info, index) => (
                <th key={info.name + index} onClick={() => sort(info.name)}>
                  {info.label}
                </th>
              ))}
              {buttons && (
                <th style={{ width: buttonsWidth }}>
                  Acciones
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {paginatedData && paginatedData.length > 0 ? (
              paginatedData.map((trData, index) => (
                <tr key={index}>
                  {multiSelect && (
                    <td>
                      <input
                        type={"checkbox"}
                        checked={checkedItems.includes(trData._id)}
                        onChange={checkboxChange}
                        value={trData._id}
                      />
                    </td>
                  )}
                  {tableHeader.map((tdData, index) => (
                    <td key={index}>
                      {getNestedValue({
                        obj: trData,
                        path: tdData.name,
                        positive: tdData.positive,
                        negative: tdData.negative,
                        replacement: tdData.replacement,
                        formatterDate: tdData.formatterDate,
                        existValueCondition: tdData.existValueCondition,
                        defaultValue: tdData.defaultValue
                      })}
                    </td>
                  ))}
                  {buttons && (
                    <td style={{ width: buttonsWidth, display: "flex" }}>
                      {buttons.map(({ overlay, icon, className, action, path, confirmMessage, conditional }, index) => {
                        if (conditional) {
                          if (conditional.values) {
                            if (!conditional.values.find(item => item === trData[conditional.key])) {
                              return null;
                            }
                          } else if (conditional.opossiteValues) {
                            let find = conditional.opossiteValues.find(item => item === trData[conditional.key]);
                            if (find || find === 0) {
                              return null;
                            }
                          } else if (conditional.null) {
                            if (!trData[conditional.key]) {
                              return null;
                            }
                          }
                        }
                        return (
                          <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={<Tooltip>{overlay}</Tooltip>} key={index}>
                            <Button
                              className={styles[className]}
                              onClick={
                                action
                                  ? () => {
                                      window.confirm(confirmMessage) && action(trData._id, trData);
                                    }
                                  : () => {
                                      navigate(`/${path}/${trData._id}`);
                                    }
                              }
                            >
                              <FontAwesomeIcon icon={icon} className="fa-table" />
                            </Button>
                          </OverlayTrigger>
                        );
                      })}
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={tableHeader.length + (multiSelect ? 1 : 0) + (buttons ? 1 : 0)}>
                  NO HAY DATOS QUE SE AJUSTEN A SU BUSQUEDA
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        <Col className="text-center" xs={12}>
          {totalPages > 1 && (
            <Pagination>
              {statePage > 1 && (
                <Pagination.First
                  key={statePage - 1}
                  onClick={() => {
                    setStatePage(0);
                  }}
                />
              )}
              {showPagination()}
              {statePage < totalPages - 1 && (
                <Pagination.Last
                  key={"last"}
                  onClick={() => {
                    setStatePage(totalPages - 1);
                  }}
                />
              )}
            </Pagination>
          )}
        </Col>
      </Row>
    </>
  );
}
